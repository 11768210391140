var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-OfferView"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"slider"},[_c('SliderComponent',{attrs:{"sliderConfig":{
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          centerMode: true,
          autoplay: false,
          autoplaySpeed: 2000,
          responsive: [
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  // arrows: false,
                }
              }
          ]
          }}},[_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_rejestracja.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_listy.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_zadania.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_tworzenie_z_1.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_tworzenie_z_2.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/panel_uczniowie.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/front_1.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/front_2.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/front_3.jpg"),"alt":""}})]),_c('div',{staticClass:"slider__slide"},[_c('img',{attrs:{"src":require("@/assets/slider/front_4.jpg"),"alt":""}})])])],1),_vm._m(1),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('div',{staticClass:"heading__text"},[_vm._v(" Chcesz tworzyć strony/aplikacje takie jak ")]),_c('div',{staticClass:"heading__text heading__text--title"},[_vm._v(" ucze.net? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer"},[_c('div',{staticClass:"offer__heading"},[_c('div',{staticClass:"offer__heading__text"},[_vm._v(" Oferuję pomoc w ")])]),_c('div',{staticClass:"offer__list"},[_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Tworzeniu stron internetowych / aplikacji ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Debugowaniu i rozwiązywaniu problemów w istniejących stronach / aplikacjach ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Opanowaniu dobrych praktyk: Mobile First, BEM ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Pracy z GiT'em. ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Tworzeniu aplikacji do portfolio. ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" Pisaniu API opartych na Node.js, Express oraz MongoDB... ")])]),_c('div',{staticClass:"offer__list__item"},[_c('div',{staticClass:"offer__list__item__text"},[_vm._v(" ... oraz realizacji dowolnych pomysłów ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('div',{staticClass:"price__quote"},[_c('div',{staticClass:"price__quote__text"},[_vm._v(" 20zł/h ")]),_c('div',{staticClass:"price__quote__text price__quote__text--up"},[_vm._v(" * ")])]),_c('div',{staticClass:"price__info"},[_c('div',{staticClass:"price__info__text price__info__text--up"},[_vm._v(" * ")]),_c('div',{staticClass:"price__info__text"},[_vm._v(" Kwota zależna od ilości godzin, rodzaju materiału, sposobu prowadzenia zajęć itp. ")])])])
}]

export { render, staticRenderFns }